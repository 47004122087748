<template>
    <Modal
        class="wrap"
        :title="$t('pages.screen.setNetWork')"
        v-model="modal"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Form ref="formValidate" style="width: 80%;" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem label="">
                <p v-if="!terminals.length">{{ $t('pages.screen.name') }}: {{ deviceInfo.name }}</p>
                <p v-else>{{ $t('common.selected') }}{{ terminals.length }}{{ $t('pages.screen.unit') }}{{ terminals.length>1?$t('pages.screen.screens'):$t('pages.screen.screen') }}</p>
                <p>{{ $t('pages.screen.keepConnected') }}</p>
            </FormItem>
            <FormItem label="WIFI" prop="ssid">
                <Input v-model="formValidate.ssid" :placeholder="$t('common.input')"></Input>
            </FormItem>
            <FormItem :label="$t('pages.screen.password')" prop="password">
                <Input v-model="formValidate.password" :placeholder="$t('common.input')"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            terminals: {
                default: () => {
                    return []
                }
            },
            deviceInfo: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    ssid: '',
                    password: ''
                }
            }
        },
        computed: {
            ruleValidate() {
                return {
                    ssid: [
                        { required: true, type: 'string', message: this.$t('common.input'), trigger: 'blur' }
                    ],
                    password: [
                        { required: true, type: 'string', message: this.$t('common.input'), trigger: 'blur' },
                        { min: 8, message: this.$t('common.limit2'), trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.formValidate = {
                    ssid: '',
                    password: ''
                }
                this.$refs['formValidate'].resetFields();
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.$Modal.confirm({
                            title: this.$t('pages.screen.tipTit'),
                            content: `<p>${this.$t('pages.screen.netWorkInfoTip')}</p><br><p>wifi: ${this.formValidate.ssid}</p><p>${this.$t('pages.screen.password')}：${this.formValidate.password}</p>`,
                            okText: this.$t('pages.screen.confirmSet'),
                            cancelText: this.$t('pages.screen.reset'),
                            closable: true,
                            onOk: () => {
                                let params = {
                                        ssid: this.formValidate.ssid,
                                        password: this.formValidate.password
                                    },
                                    _this = this;
                                if (this.terminals.length) {
                                    params.rowid_list = this.terminals
                                } else {
                                    params.rowid_list = [this.deviceInfo.rowid]
                                }
                                this.loading = true
                                this.$api.put('terminals/wifi', params).then(() => {
                                    setTimeout(function () {
                                        _this.$Modal.success({
                                            title: this.$t('common.tip'),
                                            content: this.$t('pages.screen.netWorkInfoTip2'),
                                            okText: this.$t('common.gotIt'),
                                            closable: true,
                                            onOk: () => {
                                                _this.cancel()
                                                _this.$emit('confirm')
                                            }
                                        });

                                    }, 500)
                                }).finally(() => {
                                    this.loading = false
                                })
                            }
                        });
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped>
    
</style>