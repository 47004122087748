<template>
        <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.screen.screenRecord')"
        :width="940"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
            <Table border :columns="columns" :data="list" :loading="loading">
                <template slot-scope="{ row }" slot="from">
                    <span>{{ row.content.from.short_message }}</span>
                </template>
                <template slot-scope="{ row }" slot="to">
                    <span>{{ row.content.to.short_message }}</span>
                </template>
                <template slot-scope="{ row }" slot="operator">
                    <span>{{ row.operator.name }}</span>
                </template>
            </Table>
            <Page
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
            <div slot="footer" hide-footer></div>
        </Modal>
</template>
<script>
    import util from '@/libs/util.js'

    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            terminal_id: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                current: 1,
                pageSize: 10,
                total: 0,
                list: []
            }
        },
        computed: {
            columns() {
                return [
                    {
                        title: this.$t('pages.screen.transferSource'),
                        slot: 'from',
                        align: 'center'
                    },
                    {
                        title: this.$t('pages.screen.transferTarget'),
                        slot: 'to',
                        align: 'center'
                    },
                    {
                        title: this.$t('common.operationTime'),
                        key: 'create_time',
                        align: 'center'
                    },
                    {
                        title: this.$t('common.operator'),
                        slot: 'operator',
                        align: 'center'
                    }
                ]
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) this.getData()
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.current = 1
                this.total = 0
                this.list = []
                this.$emit('cancel')
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    target: 'terminal',
                    target_rowid: this.terminal_id,
                    action: 'transfer',
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                if (this.loading) return
                this.loading = true
                this.$api.get('action_logs/search', { params }).then(({ data, total_count }) => {
                    data.map((item) => {
                        item.create_time = util.handleTime(item.create_time)
                        return item
                    })
                    this.list = data
                    this.total = total_count
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            }
        },
        created() {}
    }
</script>
<style scoped>
    .page {
        margin-top: 20px;
    }
</style>