<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.screen.addGroup')"
        width="387"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Form ref="formValidate" class="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem :label="$t('pages.screen.groupName')" prop="name">
                <Input v-model="formValidate.name" :placeholder="$t('pages.screen.inputGroupName')"></Input>
            </FormItem>
            <FormItem :label="$t('pages.screen.dir')" prop="dir">
                <Select v-model="formValidate.dir" :placeholder="$t('pages.screen.selectDir')" transfer>
                    <Option v-for="(item, index) in dirList" :key="index" :value="item.dir">{{ item.text[lang]?item.text[lang]:item.text._ }}</Option>
                </Select>
                <p>{{ $t('pages.screen.addGroupTip') }}</p>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            dirList: {
                default: () => {
                    return []
                }
            },
            department_id: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                formValidate: {
                    name: '',
                    dir: 'horizontal'
                },
                loading: false
            }
        },
        computed: {
            ruleValidate() {
                return {
                    name: [
                        { required: true, message: this.$t('pages.screen.inputGroupName'), trigger: 'blur' },
                        { type: 'string', max: 32, message: this.$t('common.upTo32Characters'), trigger: 'blur' }
                    ],
                    dir: [
                        { required: true, message: this.$t('pages.screen.selectDir'), trigger: 'change' }
                    ]
                }
            },
            lang() {
                return this.$store.state.lang
            }
        },
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let params = {
                            department_id: this.department_id,
                            name: this.formValidate.name,
                            dir: this.formValidate.dir
                        }
                        this.$api.post('terminal_groups', params).then(({ rowid, name, cover_uri }) => {
                            this.$emit('confirm', { rowid: rowid, name: name, cover_uri: cover_uri, dir: this.formValidate.dir, department_id: this.department_id })
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            }

        },
        created() {}
    }
</script>
<style scoped lang="less">
.formValidate {
    width: 80%;
}  
</style>