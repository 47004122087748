<template>
    <div id="screen-group">
        <div class="header">
            <Row>
                <Col span="12">
                    <ul class="screen-tab-wrap" @click="handleTarget">
                        <li class="tab target" data-name="all">{{ $t('pages.screen.allScreens') }}({{ total }})</li>
                        <li v-allow="'deploy'" class="tab" data-name="group">{{ $t('pages.screen.group') }}({{ groupTotal }})</li>
                    </ul>
                </Col>
                <Col v-if="currentTab === 'all'" span="12" class="clearfix">
                    <Button class="float-right" type="primary" @click="handleLabelMag">{{ $t('pages.label.labelMag') }}</Button>
                    <Button v-allow="'device'" class="float-right margin-right" type="primary" @click="handleAdd">{{ $t('pages.screen.addScreen') }}</Button>
                    <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.screen.inputSearch')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <div v-if="currentTab === 'all'">
                <div class="action-bar clearfix">
                    <template  v-if="checkAllGroup.length === 0">
                        <Dropdown class="status" trigger="click" placement="bottom-start" >
                            <Icon v-if="status" class="close" type="ios-close-circle" @click="clearStatusList" />
                            <Button type="text">
                                {{ $t('common.state') }}<span v-if="status">:{{ status.text[lang]?status.text[lang]:status.text._ }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem v-for="(item, index) in statusList" :key="index" @click.native="handleStatusList(item)">{{ item.text[lang]?item.text[lang]:item.text._ }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown class="dir" trigger="click" placement="bottom-start" >
                            <Icon v-if="dir" class="close" type="ios-close-circle" @click="clearDirList" />
                            <Button type="text">
                                {{ $t('pages.screen.dir') }}<span v-if="dir">:{{ dir.text[lang]?dir.text[lang]:dir.text._ }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem v-for="(item, index) in dirList" :key="index" @click.native="handleDirList(item)">{{ item.text[lang]?item.text[lang]:item.text._ }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown class="dropdown" trigger="click" placement="bottom-start">
                            <Icon v-if="category" class="close" type="ios-close-circle" @click="clearCategoryList" />
                            <Button type="text">
                                {{ $t('pages.screen.category') }}<span v-if="category">:{{ category.text[lang]?category.text[lang]:category.text._ }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem v-for="(item, index) in terminals_category" :key="index" @click.native="handleCategoryList(item)">{{ item.text[lang]?item.text[lang]:item.text._ }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown class="dropdown dropdown-label" trigger="click"  placement="bottom-start" >
                            <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                            <Button type="text">
                                {{ $t('pages.label.label') }}<span v-if="label">:{{ label.name }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <load-more v-if="labelList.length !== 0"  :onRefresh="handleReachBottom" :disabled="isLoaded" height="165px">
                                    <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                                        <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                                    </Tooltip>
                                </load-more>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown v-if="is_org" class="dropdown" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                            <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                            <Button type="text">
                                {{ $t('navigation.structure') }}
                                <div v-if="nodeList.length" class="tree line-overflow">
                                    <span>:</span>
                                    <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                        <span v-if="index !== nodeList.length - 1">,</span>
                                    </span>
                                </div>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                    <template v-allow="'device'" v-else>
                        <Button class="margin-right" type="primary" @click="handleBatchVolume">{{ $t('pages.screen.volume') }}</Button>
                        <Button class="margin-right" type="primary" @click="handleBatchOnOff">{{ $t('pages.screen.onOff') }}</Button>
                        <Button class="margin-right" type="primary" @click="handleTransfer">{{ $t('pages.screen.transfer') }}</Button>
                        <Button class="margin-right" type="primary" @click="handleBatchWifi">{{ $t('pages.screen.setNetWork') }}</Button>
                        <Button class="margin-right" type="primary" @click="handelLabel(checkAllGroup)">{{ $t('pages.label.editLabel') }}</Button>
                        <Button type="primary" @click="handleRemove(checkAllGroup)">{{ $t('common.del') }}</Button>
                    </template>
                    <Checkbox
                        v-allow="'device'"
                        v-show="list.length !== 0"
                        class="checkbox-all float-right"
                        :indeterminate="indeterminate"
                        :value="checkAll"
                        @click.prevent.native="handleCheckAll">
                        <span v-if="checkAllGroup.length === 0">{{ $t('common.selectAll') }}</span>
                        <span v-else>{{ $t('common.selected') }}{{ checkAllGroup.length }}{{ $t('pages.screen.unit') }}{{ $t(checkAllGroup.length>1?'pages.screen.screens':'pages.screen.screen') }}</span>
                    </Checkbox>
                    <div v-show="currentTab==='all'" class="state-counts float-right">
                        <span class="online">{{ $t('pages.screen.online') }}:{{ state_counts.online }}</span>
                        <span>{{ $t('pages.screen.offline') }}:{{ state_counts.offline }}</span>
                    </div>
                </div>
                <no-data v-show="list.length === 0" :text="$t('pages.screen.noScreen')"></no-data>
                <div v-show="list.length !== 0" class="checkbox-group-wrap">
                    <CheckboxGroup class="checkbox-group" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                        <div class="checkbox-wrap" v-for="item in list" :key="item.rowid" >
                            <div class="img-wrap-block">
                                <div :class="['img-wrap', item.dir==='vertical'?'img-wrap-vertical':'']">
                                    <img class="image-center" :src="`/cym/${item.cover_uri}`">
                                    <div class="device-status">
                                        <Badge :status="item.state === 'online' ? 'success' : 'error'" />
                                        <span class="text">{{ item.state_text[lang]?item.state_text[lang]:item.state_text._ }}</span>
                                    </div>
                                </div>
                                <span class="category">
                                    <span class="category-text">{{ item.category_text[lang]?item.category_text[lang]:item.category_text._ }}</span>
                                </span>
                            </div>
                            <div v-if="checkAllGroup.length === 0" class="mask pointer" @click="handlePlayPlan(item)"></div>
                            <div class="device-info">
                                <!-- <h2 class="device-name line-overflow">{{ item.name }}</h2> -->
                                <div class="device-name name-wrap pointer" @click="showElement($event, item.rowid, 10)">
                                    <div class="name line-overflow">
                                        {{ item.name }}
                                    </div>
                                    <Icon class="icon" type="md-create" />
                                </div>
                                <div v-if="item.department_name && is_org" class="device-site line-overflow">{{ item.department_name }}</div>
                                <div v-if="checkAllGroup.length === 0" class="device-plan line-overflow pointer" @click="handlePlayPlan(item)">{{ $t('pages.screen.plan') }}</div>
                            </div>
                            <Checkbox v-allow="'device'" :class="['checkbox', checkAllGroup.length === 0 ? '' : 'checked']" :label="item.rowid" ><span>&nbsp;</span></Checkbox>
                            <Dropdown v-allow="'device'" v-if="checkAllGroup.length === 0" class="more" placement="bottom-start" trigger="click" >
                                <a href="javascript:void(0)">
                                    <Icon class="icon-more" type="md-more" color="#2d2d2d" :size="22" />
                                </a>
                                <DropdownMenu slot="list" >
                                    <DropdownItem @click.native="handelLabel([item.rowid])">{{ $t('pages.label.editLabel') }}</DropdownItem>
                                    <DropdownItem @click.native="handleEdit(item)">{{ $t('common.edit') }}</DropdownItem>
                                    <DropdownItem @click.native="handleVolume(item.rowid)">{{ $t('pages.screen.volume') }}</DropdownItem>
                                    <DropdownItem @click.native="handleOnOff([item.rowid])">{{ $t('pages.screen.onOff') }}</DropdownItem>
                                    <DropdownItem @click.native="handleWifi(item)">{{ $t('pages.screen.setNetWork') }}</DropdownItem>
                                    <DropdownItem @click.native="handleRecords(item)">{{ $t('pages.screen.record') }}</DropdownItem>
                                    <DropdownItem @click.native="handleRemove([item.rowid])">{{ $t('common.del') }}</DropdownItem>
                                    <!-- <DropdownItem v-if="item.need_dfu" @click.native="handleUpgrade(item)">升级</DropdownItem> -->
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </CheckboxGroup>
                    <Spin fix v-if="loading"></Spin>
                </div>
                <Page
                    v-show="list.length !== 0"
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
            </div>
            <screen-group
                v-else
                :is_org="is_org"
                :dirList="dirList"
                :statusList="statusList"
                :department_id="department_id"
                :tree_data="tree_data"
                :handleGroupTotal="handleGroupTotal"
            ></screen-group>
        </div>
        <add-modal
            :show="addModalShow"
            :department_id="department_id"
            :title="$t('pages.screen.addScreen')"
            :lang="lang"
            @cancel="handleAddCancel"
            @confirm="handleAddSubmit"
        ></add-modal>
        <edit-modal
            :show="editModalShow"
            :dirList="dirList"
            :editInfo="editInfo"
            :lang="lang"
            @cancel="handleEditCancel"
            @confirm="handleEditSubmit"
        ></edit-modal>
        <play-plan-modal
            :show="playPlanModalShow"
            :deviceInfo="deviceInfo"
            :lang="lang"
            @cancel="handlePlayPlanModalCancel"
        ></play-plan-modal>
        <volume-modal
            :show="volumeModalShow"
            :volumeIds="volumeIds"
            @cancel="handleVolumeCancel"
            @confirm="handleVolumeSubmit"
        ></volume-modal>
        <on-off-modal
            :show="onOffModalShow"
            :onOffIds="onOffIds"
            :onRefresh="handleOnOffSubmit"
            @cancel="handleOnOffCancel"
            @confirm="handleOnOffSubmit"
        ></on-off-modal>
        <transfer-modal
            :show="transferModalShow"
            :terminals="checkAllGroup"
            @cancel="handleTransferCancel"
            @confirm="handleTransferConfirm"
        ></transfer-modal>
        <wifi-modal
            :show="wifiModalShow"
            :terminals="checkAllGroup"
            :deviceInfo="deviceInfo"
            @cancel="handleWifiCancel"
            @confirm="handleWifiConfirm"
        ></wifi-modal>
        <records-modal
            :show="recordsModalShow"
            :terminal_id="deviceInfo.rowid"
            @cancel="handleRecordsCancel"></records-modal>
        <label-mag-modal
            :show="labelMagModalShow"
            :bucket_token="bucket_token"
            label_type="terminal"
            @cancel="handleLabelMagCancel"
            @refresh="handleRefreshLabel"
            @clear="clearLabelList"></label-mag-modal>
        <edit-label
            :show="labelModalShow"
            :material_ids="material_ids"
            :bucket_token="bucket_token"
            label_type="terminal"
            @confirm="handleLabelConfirm"
            @cancel="handleLabelCancel"
            @refresh="handleRefresh"></edit-label>
    </div>  
</template>
<script>
    import addModal from './components/add'
    import editModal from './components/edit'
    import playPlanModal from './components/playPlan'
    import volumeModal from './components/volume'
    import onOffModal from './components/onOff'
    import screenGroup from './group/index'
    import noData from '@/views/components/noData'
    import transferModal from './components/transfer'
    import wifiModal from './components/wifi'
    import recordsModal from './components/recordsModal'
    import labelMagModal from '@/views/spaces/components/labelMag'
    import editLabel from '@/views/spaces/components/editLabel'
    import loadMore from '@/views/components/loadMore'
    import { checkbox, dropdownTree, rename } from '@/mixins/index'

    export default {
        name: "",
        mixins: [checkbox, dropdownTree, rename],
        components: {
            addModal,
            editModal,
            playPlanModal,
            volumeModal,
            onOffModal,
            screenGroup,
            noData,
            transferModal,
            wifiModal,
            recordsModal,
            labelMagModal,
            editLabel,
            loadMore
        },
        props: [],
        data () {
            return {
                total: 0,
                loading: false,
                query: '',
                indeterminate: false,
                checkAll: false,
                checkAllGroup: [],
                dirList: [],
                dir: null,
                status: null,
                statusList: [],
                current: 1,
                pageSize: 32,
                list: [],
                addModalShow: false,
                editModalShow: false,
                playPlanModalShow: false,
                editInfo: {},
                deviceInfo: {},
                state_counts: {},
                volumeModalShow: false,
                volumeIds: [],
                onOffModalShow: false,
                onOffIds: [],
                currentTab: 'all',
                groupTotal: 0,
                transferModalShow: false,
                nodeList: [],
                data: [],
                treeList: [],
                wifiModalShow: false,
                recordsModalShow: false,
                category: null,
                labelMagModalShow: false,
                label: null,
                labelList: [],
                labelPageSize: 10,
                labelCurrent: 1,
                isLoaded: false,
                material_ids: [],
                labelModalShow: false
            }
        },
        computed: {
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            tree_data() {
                return [this.$store.state.user_tree]
            },
            terminals_category() {
                return this.$store.state.terminals_category
            },
            bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            },
            lang() {
                return this.$store.state.lang
            }
        },
        methods: {
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            handelLabel(material_ids) {
                this.labelModalShow = true
                this.material_ids = material_ids
            },
            handleRefresh() {
                this.getData()
                this.handleRefreshLabel()
            },
            handleRefreshLabel() {
                this.labelCurrent = 1
                this.labelList =  []
                this.getLabelList()
            },
            handleLabelConfirm() {
                this.handleLabelCancel()
                this.checkAllGroup = []
                this.indeterminate = false
                this.checkAll = false
            },
            handleLabelCancel() {
                this.labelModalShow = false
            },
            getLabelList() {
                let params = {
                    bucket_token: this.bucket_token,
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize,
                    label_type: 'terminal'
                }
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                    }
                    if (this.labelList.length == total_count) {
                        this.isLoaded = true
                    } else {
                        this.isLoaded = false
                    }
                })
            },
            clearLabelList() {
                this.current = 1
                this.label = ''
                this.getData()
            },
            handleLabelMag() {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                this.labelMagModalShow = true
            },
            handleLabelMagCancel() {
                this.labelMagModalShow = false
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.getData()
            },
            handleBatchVolume() {
                this.volumeIds = this.checkAllGroup
                this.volumeModalShow = true
            },
            handleRecords(item) {
                this.recordsModalShow = true
                this.deviceInfo = item
            },
            handleRecordsCancel() {
                this.recordsModalShow = false
                this.deviceInfo = {}
            },
            handleRemove(list) {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.goods.delCon2'),
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    loading: true,
                    closable: true,
                    onOk: () => {
                        let newList = [...list],
                            len = newList.length,
                            str = ''
                        for (let i = 0; i < len; i++) {
                            str += newList[i]
                            if (i !== len - 1) {
                                str += ','
                            }
                        }
                        this.$api.delete(`terminals/${str}`).then(()=>{
                            this.$Message.success(this.$t('message.success'));
                            this.indeterminate = false
                            this.checkAllGroup = []
                            this.checkAll = false
                            let len = list.length;
                            if(this.total - len === (this.current - 1) * this.pageSize && this.current > 1) {
                                this.current -= 1
                            }
                            this.getData()
                        }).finally(() => {
                            this.$Modal.remove();
                        })

                    }
                });
            },
            handleUpgrade(row) {
                this.$Modal.confirm({
                    title: this.$t('pages.screen.upgrade'),
                    loading: true,
                    closable: true,
                    content: `<p>${this.$t('pages.screen.latestSysVer')}：${row.dfu_firmware.system_ver_number}，${this.$t('pages.screen.currentVer')}：${row.firmware.system_ver_number}</p><p>${this.$t('pages.screen.latestAppVer')}：${row.dfu_firmware.application_ver_number}，${this.$t('pages.screen.currentVer')}：${row.firmware.application_ver_number}</p><p style="margin-top: 20px;">${this.$t('pages.screen.sysUpdateCon')}</p><p>${row.dfu_firmware.system_description[this.lang]?row.dfu_firmware.system_description[this.lang]:row.dfu_firmware.system_description._}<p style="margin-top: 20px;">${this.$t('pages.screen.appUpdateCon')}</p><p>${row.dfu_firmware.application_description[this.lang]?row.dfu_firmware.application_description[this.lang]:row.dfu_firmware.application_description._}</p><p style="margin-top: 20px;">${this.$t('pages.screen.tipTit')}：</p>
                            <p>1、${this.$t('pages.screen.tipCon')}</p><p>2、${this.$t('pages.screen.tipCon2')}</p>`,
                    onOk: () => {
                        this.$api.put(`terminals/${row.rowid}/dfu`).then(() => {
                            this.$Modal.remove()
                            this.$Message.success(this.$t('pages.screen.waiting'))
                            this.getData()
                        })
                    }
                })
            },
            handleDirList(item) {
                this.current = 1
                this.dir = item
                this.getData()
            },
            clearDirList() {
                this.current = 1
                this.dir = null
                this.getData()
            },
            handleCategoryList(item) {
                this.current = 1
                this.category = item
                this.getData()
            },
            clearCategoryList() {
                this.current = 1
                this.category = null
                this.getData()
            },
            handleStatusList(item) {
                this.current = 1
                this.status = item
                this.getData()
            },
            clearStatusList() {
                this.current = 1
                this.status = null
                this.getData()
            },
            getStatusList() {
                this.$api.get('terminals/status/list').then(({ data }) => {
                    this.statusList = data
                })
            },
            getDirList() {
                this.$api.get('terminals/dir/list').then(({ data }) => {
                    this.dirList = data
                })
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    repeater_type: 'display'
                }
                if (this.is_org) {
                    let len = this.nodeList.length
                    if (len) {
                        let department_id_list = []
                        this.nodeList.map((item) => {
                            department_id_list.push(item.department_id)
                        })
                        params.department_id_list = JSON.stringify(department_id_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'department_id')
                        params.department_id_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.department_id = this.department_id
                }
                if (this.dir) {
                    params.dir = this.dir.dir
                }
                if (this.status) {
                    params.state = this.status.status
                }
                if (this.category) params.category = this.category.key
                if (this.label) {
                    params.label_id_list = JSON.stringify([this.label.rowid])
                }

                this.loading = true
                this.checkAll = false
                this.indeterminate = false

                this.$api.get('terminals/search', { params }).then(({ data, total_count, page, state_counts }) => {
                    if (page === this.current) {
                        let isAll = true
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.map((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid)== -1) {
                                isAll = false
                            } else {
                                this.indeterminate = true
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }

                        this.list = data
                        this.total = total_count
                        this.state_counts = state_counts
                        if (total_count === 0) {
                            this.$store.dispatch('GenerateRoutes').finally(() => {
                                this.loading = false

                            })
                        } else {
                            this.loading = false
                        }
                        if (this.status) {
                            this.total = state_counts[this.status.status]
                        }
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{})
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            handleAdd() {
                this.addModalShow = true
            },
            handleAddCancel() {
                this.addModalShow = false
            },
            handleAddSubmit() {
                this.$store.dispatch('GET_TERMINALS_CATEGORY')
                this.getData()
            },
            handleEdit(item) {
                this.editModalShow = true
                this.editInfo = item
            },
            handleEditCancel() {
                this.editModalShow = false
            },
            handleEditSubmit() {
                this.getData()
            },
            handlePlayPlan(item) {
                this.playPlanModalShow = true
                this.deviceInfo = item
            },
            handlePlayPlanModalCancel() {
                this.playPlanModalShow = false
            },
            handleVolume(rowid) {
                this.volumeIds = [rowid]
                this.volumeModalShow = true
            },
            handleVolumeCancel() {
                this.volumeModalShow = false
            },
            handleVolumeSubmit(volume) {
                let params = {
                    rowid_list: this.volumeIds,
                    volume: volume
                }
                this.$api.put('terminals/volume', params).then(() => {
                    this.$Message.success(this.$t('message.success'))
                })
            },
            //批量设置开关机
            handleBatchOnOff() {
                this.onOffIds = this.checkAllGroup
                this.onOffModalShow = true
            },
            handleOnOffCancel() {
                this.onOffModalShow = false
            },
            handleOnOffSubmit(params, done) {
                if (params.current_state) {
                    this.$api.put('terminals/screen/power_state', params).then(() => {
                        this.$Message.success(this.$t('message.success'))
                        this.handleOnOffCancel()
                        this.checkAllGroup = []
                        this.indeterminate = false
                        this.checkAll = false
                    }).finally(() => {
                        done()
                    })
                    return
                }
                this.$api.put('terminals/screen/schedule', params).then(() => {
                    this.$Message.success(this.$t('message.success'))
                    this.handleOnOffCancel()
                    this.checkAllGroup = []
                    this.indeterminate = false
                    this.checkAll = false
                }).finally(() => {
                    done()
                })
            },
            handleOnOff(list) {
                this.onOffIds = list
                this.onOffModalShow = true
            },
            handleTarget(e) {
                let eles = e.currentTarget.children,
                    len = eles.length;

                for (var i = 0; i < len; i++) {
                    eles[i].setAttribute('class', 'tab')
                }

                e.target.setAttribute('class', 'tab target')
                if (e.target.dataset.name === this.currentTab) return
                this.currentTab = e.target.dataset.name
                if (this.currentTab === 'all') {
                    this.reset()
                }
            },
            handleGroupTotal(total) {
                this.groupTotal = total
            },
            reset() {
                this.current = 1
                this.total = 0
                this.list = []
                this.indeterminate = false
                this.checkAll = false
                this.checkAllGroup = []
                this.dir = null
                this.status = null
                this.getData()
            },
            handleTransfer() {
                this.transferModalShow = true
            },
            handleTransferCancel() {
                this.transferModalShow = false
            },
            handleTransferConfirm() {
                this.indeterminate = false
                this.checkAllGroup = []
                this.checkAll = false
                this.getData()
            },
            handleBatchWifi() {
                this.wifiModalShow = true
            },
            handleWifi(item) {
                this.wifiModalShow = true
                this.deviceInfo = item
            },
            handleWifiCancel() {
                this.wifiModalShow = false
            },
            handleWifiConfirm() {
                this.checkAllGroup = []
                this.deviceInfo = {}
            },
            handleRename(name, rowid) {
                let params = {
                    name: name
                }
                this.$api.put(`terminals/${rowid}/name`, params).then(()=>{
                    this.$Message.success(this.$t('message.success'))
                    this.getData()
                }).catch(() => {})
            },
        },
        mounted() {
            this.getData()
            this.getStatusList()
            this.getDirList()
            this.getLabelList()
            let permissionList = this.$store.state.rolesList
            if (!permissionList.length || permissionList.indexOf('deploy') > -1) {
                //获取屏幕分组数量
                let params = {
                        query: '',
                        dir: '',
                        start: 0,
                        count: this.pageSize
                    }
                if (this.is_org) {
                    this.treeList = []
                    this.getTreeList(this.tree_data, 'department_id')
                    params.department_id_list = JSON.stringify(this.treeList)
                } else {
                    params.department_id_list = JSON.stringify([this.department_id])
                }
                this.$api.get('terminal_groups/search', { params }).then(({ total_count }) => {
                    this.groupTotal = total_count
                })
            }
            this.data = this.handleRecursive(this.tree_data)
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
@deep: ~'>>>';
.dir, .status, .dropdown {
    position: relative;
    .close {
        display: none;
    }
    &:hover {
        .close {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    .tree {
        display: inline-block;
        max-width: 100px;
        vertical-align: middle;
    }


}

.status {
    margin-left: -15px;
}
.checkbox-group-wrap {
    position: relative;
    .checkbox-group {
        width: calc(100% + 8px);
        .checkbox-wrap {
            position: relative;
            display: inline-block;
            margin: 0 8px 31px 0;
            padding-left: 0.7%;
            border: 1px solid #d3d3d3;
            width: calc(25% - 8px);
            padding-top: 13%;
            background: #fff;
            &:hover {
                .mask {
                    opacity: 1;
                }
            }
            .mask {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.08);
                transition: opacity .3s ease;
            }
            .checkbox {
                position: absolute;
                top: 0;
                left: 0;
                margin-right: 0;
                padding: 5px;
            }
            .checked {
                display: inline-block;
                width: 100%;
                height: 100%;

            }
            .img-wrap-block {
                position: absolute;
                left: 0;
                top: 0;
                width: 38%;
                height: 100%;
                background: #f6f7f9;
                .category {
                    position: absolute;
                    left: 12%;
                    bottom: 4%;
                    height: 16px;
                    padding: 0 2px;
                    border-radius: 8px;
                    background: @primary-color;
                    color: #ffff;
                    font-size: 10px;
                    text-align: center;
                    line-height: 16px;
                    white-space:nowrap;
                    .category-text {
                        display: block;
                        transform: scale(0.8);
                    }
                }
                .img-wrap {
                    position: absolute;
                    top: 31%;
                    bottom: 31%;
                    left: 10%;
                    right: 10%;
                    .device-status {
                        position: absolute;
                        left: 5%;
                        bottom: 12%;
                        width: 40px;
                        height: 16px;
                        padding-left: 5px;
                        border-radius: 4px;
                        background: #000;
                        color: #fff;
                        line-height: 16px;
                        .ivu-badge-status {
                            width: 10px;
                            height: 100%;
                        }
                        .text {
                            position: absolute;
                            left: 12px;
                            top: 50%;
                            transform: translateY(-50%) scale(0.8);
                            font-size: 10px;
                            display: block;
                        }
                    }
                }
                .img-wrap-vertical {
                    position: absolute;
                    top: 15%;
                    bottom: 19%;
                    left: 25%;
                    right: 25%;
                    .category {
                        left: 0%;
                        top: 7%;
                    }
                    .device-status {
                        position: absolute;
                        left: 8%;
                        bottom: 7%;
                    }
                }
            }
            .device-info {
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translateY(-50%);
                width: 58%;
                min-width: 100px;
                .device-name {
                    position: relative;
                    height: 33px;
                    font-weight: 700;
                    font-size: 10px;
                    width: calc(100% + 16px);
                    @{deep} .input {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: calc(100% - 16px) !important;
                        transform: translateY(-50%);
                        vertical-align: middle;
                    }
                    .name {
                        display: inline-block;
                        position: relative;
                        max-width: calc(100% - 24px);
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 33px;
                        vertical-align: middle;
                    }
                    .icon {
                        vertical-align: middle;
                    }
                }
                .device-site {
                    font-size: 10px;
                }
                .device-plan {
                    display: inline-block;
                    margin-top: 4px;
                    padding: 0 10px;
                    height: 28px;
                    max-width: 100px;
                    background: @primary-color;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    line-height: 28px;
                    font-size: 12px;
                }
            }
            .more {
                position: absolute;
                right: 3%;
                bottom: 3%;
                .icon-more {
                    border: 2px solid #2d2d2d;
                    border-radius: 50% 50%;
                }
            }
        }
        @media screen and (min-width: 1600px) {
            .checkbox-wrap {
                .img-wrap-block {
                    .img-wrap {
                        .device-status {
                            bottom: 15%;
                        }
                    }
                    .img-wrap-vertical {
                        .device-status {
                            bottom: 9%;
                        }
                    }
                }
                .device-info {
                    .device-name {
                        font-size: 14px;
                    }
                    .device-site {
                        font-size: 12px;
                    }
                    .device-plan {
                        padding: 0 17px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.target {
    color: @primary-color;
}
.main-content {
    .state-counts {
        line-height: 32px;
        font-size: 14px;
        .online {
            margin-right: 20px;
        }
    }
    .checkbox-all {
        margin-left: 10px;
        padding-top: 5.5px;
    }
}
.screen-tab-wrap {
    display: inline-block;
    margin: 0 2px 0 -10px;
    height: 32px;
    font-size: 14px;
    .tab {
        cursor: pointer;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        padding: 0 20px 0 10px;
        height: 100%;
        line-height: 32px;
    }
}
.header {
    height: 42px;
}
#screen-group {
    position: relative;
    .dropdown-label {
        .tooltip {
            display: block;
            width: 100%;
            .label {
                max-width: 179px;
            }
        }
    }
}
</style>
