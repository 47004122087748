<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="groupInfo.name"
        width="1200"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <div class="content-wrap">
            <div class="selectable-title">{{ $t('pages.screen.selectableScreen') }}({{ total }})</div>
            <Row class="action-bar">
                <Col span="18">
                    <RadioGroup class="radio-group-button" v-model="is_included_other" type="button" @on-change="handleGroup">
                        <Radio :label="0">{{ $t('pages.screen.notJoined') }}</Radio>
                        <Radio :label="1">{{ $t('pages.screen.joined') }}</Radio>
                    </RadioGroup>
                    <Dropdown v-if="is_org" class="dropdown" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                        <Icon v-if="nodeList.length" class="close pointer" type="ios-close-circle" @click="clearNodeList" />
                        <Button type="text">
                            {{ $t('navigation.structure') }}
                            <div v-if="nodeList.length" class="tree line-overflow">
                                <span>:</span>
                                <span v-for="(item,index) in nodeList" :key="item.rowid">{{ item.title }}
                                    <span v-if="index !== nodeList.length - 1">,</span>
                                </span>
                            </div>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown class="status" trigger="click" placement="bottom-start" >
                        <Icon v-if="status" class="close" type="ios-close-circle" @click="clearStatusList" />
                        <Button type="text">
                            {{ $t('common.state') }}<span v-if="status">:{{ status.text[lang]?status.text[lang]:status.text._ }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="(item, index) in statusList" :key="index" @click.native="handleStatusList(item)">{{ item.text[lang]?item.text[lang]:item.text._ }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col span="6">
                    <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.screen.inputSearch')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
            <div class="section">
                <no-data v-show="list.length === 0" :text="$t('pages.screen.noScreen')"></no-data>
                <CheckboxGroup v-show="list.length > 0" class="devices-wrap clearfix" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                    <div class="device-block" v-for="item in list" :key="item.rowid">
                        <div class="img-wrap">
                            <img class="image-center" :src="`/cym/${item.cover_uri}/thumbnail?size=[362,-1]`">
                            <div class="device-status">
                                <Badge :status="item.state === 'online' ? 'success' : 'error'" />
                                <span class="text">{{ item.state_text[lang]?item.state_text[lang]:item.state_text._ }}</span>
                            </div>
                        </div>
                        <div class="device-info">
                            <h2 class="device-title line-overflow">{{ item.name }}</h2>
                            <!-- <p class="text line-overflow">{{ item.terminal_model_text._ }}</p> -->
                        </div>
                        <Checkbox class="checkbox" :label="item.rowid" @click.native.stop="handleCheckbox(item)"><span>&nbsp;</span></Checkbox>
                    </div>
                </CheckboxGroup>
                <Checkbox
                    v-if="list.length !== 0"
                    :indeterminate="indeterminate"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll"><span v-if="checkAllGroup.length === 0">{{ $t('common.selectAll') }}</span>
                        <span v-else>{{ $t('common.selected') }}{{ checkAllGroup.length }}{{ $t('pages.screen.unit') }}{{ $t(checkAllGroup.length>1?'pages.screen.screens':'pages.screen.screen') }}</span>
                </Checkbox>
                <Spin fix v-if="loading"></Spin>
            </div>
            <Page
                v-show="list.length > 0"
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
            <div class="selected-title">{{ $t('pages.screen.selectedScreen') }}({{ pagesList.length }})
                <Button class="remove-all" type="primary" :disabled="playLoading||saveLoading||loading2" @click="handleRemoveAll">{{ $t('common.delAll') }}</Button>
            </div>
            <div class="included-section">
                <no-data v-show="list2.length === 0" :text="$t('pages.screen.noScreen')"></no-data>
                <div v-show="list2.length > 0" class="devices-wrap clearfix">
                    <div class="device-block" v-for="item in list2" :key="item.rowid">
                        <div class="img-wrap">
                            <img class="image-center" :src="`/cym/${item.cover_uri}/thumbnail?size=[362,-1]`">
                            <div class="device-status">
                                <Badge :status="item.state === 'online' ? 'success' : 'error'" />
                                <span class="text">{{ item.state_text[lang]?item.state_text[lang]:item.state_text._ }}</span>
                            </div>
                            <div class="remove-wrap">
                                <i class="iconfont icon-lajitong icon-remove pointer" @click="handleRemove(item.rowid)"></i>
                            </div>
                        </div>
                        <div class="device-info">
                            <h2 class="device-title line-overflow">{{ item.name }}</h2>
                            <!-- <p class="text line-overflow">{{ item.terminal_model_text._ }}</p> -->
                        </div>
                    </div>
                </div>
                <Spin fix v-if="loading2"></Spin>
            </div>
            <Page
                v-show="list2.length > 0"
                class="page"
                :total="pagesList.length"
                :current="current2"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage2" />
        </div>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" :disabled="playLoading||saveLoading||loading2" @click="submit">{{ $t('common.save') }}</Button>
            <Button type="primary" :disabled="saveLoading||playLoading||loading2" @click="submitAndPlay">{{ $t('common.saveAndPost') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    import noData from '@/views/components/noData'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            noData
        },
        props: {
            show: {
                default: false
            },
            statusList: {
                default: () => {
                    return []
                }
            },
            groupInfo: {
                default: () => {
                    return {}
                }
            },
            is_org: {
                default: false
            }
        },
        data () {
            return {
                loading: false,
                modal: false,
                status: null,
                query: '',
                checkAllGroup: [],
                indeterminate: false,
                checkAll: false,
                list: [],
                list2: [],
                pagesList: [],
                total: 0,
                current: 1,
                pageSize: 30,
                current2: 1,
                loading2: false,
                itemInfo: {},
                saveLoading: false,
                playLoading: false,
                plansCount: 0,
                tree_data: [],//组织架构树初始状态
                nodeList: [],//选中的节点
                treeList: [],//待提交的部门节点
                data: [],//组织架构树现有状态
                is_included_other: 0,
                request_id: null
            }
        },
        computed: {
            lang() {
                return this.$store.state.lang
            }
        },
        watch: {
            async show(val) {
                this.modal = val
                if (val) {
                    //获取组内的屏幕
                    let params = {
                        is_included: true,
                        query: this.query,
                        start: 0,
                        count: -1
                    }
                    if (this.status) {
                        params.state = this.status.status
                    }
                    this.$api.get(`terminal_groups/${this.groupInfo.rowid}/plans/search`).then(({ total_count }) => {
                        this.plansCount = total_count

                    })
                    this.loading2 = true
                    if (this.is_org) {
                        await this.getTree()
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'department_id')
                        params.department_id_list = JSON.stringify(this.treeList)
                    } else {
                        params.department_id = this.groupInfo.department_id
                    }
                    this.$api.get(`terminal_groups/${this.groupInfo.rowid}/terminals/search`, { params }).then(({ data, page , request_id}) => {
                        if (page === this.current) {
                            this.pagesList = data
                            this.nextPage2(this.current2)
                            this.request_id = request_id
                        }
                    }).finally(() => {
                        this.loading2 = false
                    })
                    //获取未在组内的屏幕
                    this.getData()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.query = ''
                this.total = 0
                this.list = []
                this.list2 = []
                this.pagesList = []
                this.checkAllGroup = []
                this.current = 1
                this.current2 = 1
                this.status = null
                this.indeterminate = false
                this.checkAll = false
                this.plansCount = 0
                this.tree_data = []
                this.data = []
                this.treeList = []
                this.nodeList = []
                this.is_included_other = 0
                this.request_id = null
                this.$emit('cancel')
            },
            clearStatusList() {
                this.current = 1
                this.status = null
                this.getData()
            },
            handleStatusList(item) {
                this.current = 1
                this.status = item
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    is_included: false,
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    is_included_other: Boolean(this.is_included_other)
                }
                if (this.status) {
                    params.state = this.status.status
                }
                if (this.is_org) {
                    let len = this.nodeList.length
                    if (len) {
                        let department_id_list = []
                        this.nodeList.map((item) => {
                            department_id_list.push(item.department_id)
                        })
                        params.department_id_list = JSON.stringify(department_id_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'department_id')
                        params.department_id_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.department_id = this.groupInfo.department_id
                }
                this.loading = true
                //解决翻页后，显示上页勾选状态问题
                this.checkAll = false;
                this.$api.get(`terminal_groups/${this.groupInfo.rowid}/terminals/search`, { params }).then(({ data, total_count, page  }) => {
                    if (page === this.current) {
                        let isAll = true
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.map((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid) === -1 || this.checkAllGroup.length === 0) {
                                isAll = false
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false
                            this.checkAll = true
                        }
                        this.list = data
                        this.total = total_count
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleCheckAll() {
                if (this.indeterminate) {
                    this.checkAll = false;
                } else {
                    this.checkAll = !this.checkAll;
                }
                this.indeterminate = false;

                if (this.checkAll) {
                    //全选当前页
                    let set = new Set(this.checkAllGroup)
                    this.list.map((item)=> {
                        if (!set.has(item.rowid)) {
                            set.add(item.rowid)
                            this.pagesList.push(item)
                            //重新获取组内当前页的数据
                            this.nextPage2(this.current2)
                        }
                    })
                    this.checkAllGroup = Array.from(set)
                } else {
                    let len = this.list.length
                    for (let i = 0; i < len; i++) {
                        let index = this.checkAllGroup.indexOf(this.list[i].rowid)
                        this.checkAllGroup.splice(index, 1)
                        for (let j = 0; j < this.pagesList.length; j++) {
                            if (this.list[i].rowid === this.pagesList[j].rowid) {
                                this.pagesList.splice(j, 1)
                                break
                            }
                        }
                    }
                    //重新获取组内当前页的数据
                    this.nextPage2(this.current2)
                }
            },
            checkAllGroupChange(e) {
                if (e.length > 0) {
                    this.indeterminate = true;
                    this.checkAll = false;
                } else {
                    this.indeterminate = false;
                    this.checkAll = false;
                }
                if (e.indexOf(this.itemInfo.rowid) === -1) {
                    //如果未选中，组内查找删除
                    this.pagesList.map((item, index) => {
                        if (this.itemInfo.rowid === item.rowid) {
                            this.pagesList.splice(index, 1)
                        }
                    })
                } else {
                    //若选中，添加到组内
                    this.pagesList.push(this.itemInfo)
                    let isAll = true
                    this.list.map((item)=>{
                        if (e.indexOf(item.rowid) === -1) {
                            isAll = false
                        }
                    })
                    this.checkAllGroup = e
                    //当前页全勾选，显示全选状态
                    if (isAll) {
                        this.indeterminate = false;
                        this.checkAll = true;
                    }
                }
                //重新获取组内当前页的数据
                this.nextPage2(this.current2)
            },
            handleCheckbox(item) {
                this.itemInfo = item
            },
            searchNew() {
                this.current = 1
                if (this.loading) return
                this.getData()
            },
            handleRemoveAll() {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.screen.removeTip'),
                    closable: true,
                    onOk: () => {
                        this.current2 = 1
                        this.list2 = []
                        this.pagesList = []
                        this.checkAllGroup = []
                        this.indeterminate = false
                        this.checkAll = false
                    }
                })
            },
            handleRemove(rowid) {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.screen.removeTip2'),
                    closable: true,
                    onOk: () => {
                        let len = this.pagesList.length
                        for (let i = 0; i < len; i++) {
                            if (rowid === this.pagesList[i].rowid) {
                                this.pagesList.splice(i, 1)
                                break;
                            }
                        }
                        if(this.pagesList.length === (this.current2 - 1) * this.pageSize && this.current2 > 1) {
                            this.current2 -= 1
                        }
                        //重新获取组内当前页的数据
                        this.nextPage2(this.current2)
                        //处理选中状态
                        this.checkAllGroup.map((item, index) => {
                            if (item === rowid) {
                                this.checkAllGroup.splice(index, 1)
                            }
                        })
                        if (this.checkAllGroup.length == 0) {
                            this.indeterminate = false
                        }
                        this.checkAll = false
                    }
                })
            },
            nextPage2(e) {
                this.current2 = e
                let start = (this.current2 - 1) * this.pageSize,
                    end = start + this.pageSize;
                this.getData2(start, end)
            },
            getData2(start, end) {
                this.list2 = this.pagesList.slice(start, end)
            },
            handleSubmit() {
                return new Promise(resolve => {
                    let terminals = []
                    this.pagesList.map((item) => {
                        terminals.push(item.rowid)
                    })
                    if (this.checkAllGroup.length == 0 || !this.plansCount) {
                        resolve(this.$api.put(`terminal_groups/${this.groupInfo.rowid}/terminals`, { terminal_rowid_list: terminals, request_id: this.request_id }))
                        return
                    }
                    this.$Modal.confirm({
                        title: this.$t('common.tip'),
                        content: this.$t('pages.screen.updatePlan'),
                        closable: true,
                        onOk: () => {
                            resolve(this.$api.put(`terminal_groups/${this.groupInfo.rowid}/terminals`, { terminal_rowid_list: terminals, request_id: this.request_id  }))
                        },
                        onCancel: () => {
                            this.saveLoading = false
                            this.playLoading = false
                        }
                    })
                })
            },
            submit() {
                this.saveLoading = true
                this.handleSubmit().then(() => {
                    this.$Message.success(this.$t('message.success'))
                    this.cancel()
                    this.$emit('confirm')
                }).finally(() => {
                    this.saveLoading = false
                })
            },
            submitAndPlay() {
                this.playLoading = true
                this.handleSubmit().then(() => {
                    this.$Message.success(this.$t('message.success'))
                    this.$emit('confirm', { terminal_count: this.pagesList.length })
                    this.cancel()
                }).finally(() => {
                    this.playLoading = false
                })
            },
            getTree() {
                let params = {
                    root_dept_id: this.groupInfo.department_id
                }
                this.loading = true
                return this.$api.get('organizations/current/departments/tree', { params }).then(({ data }) => {
                    this.tree_data = [data]
                    this.data  = this.handleRecursive(this.tree_data)
                })
            },
            handleGroup() {
                this.current = 1
                this.getData()
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.content-wrap {
    position: relative;
    padding: 0 18px;
    .selectable-title {
        margin-left: -15px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
    }
}
.action-bar {
    margin-left: -15px;
    .radio-group-button {
        margin-left: -15px;
        position: relative;
        &::after {
            content: '\200B';
            position: absolute;
            top: 50%;
            right: -10px;
            width: 1px;
            height: 15px;
            background: #b4b9bf;
            transform: translateY(-50%);
        }
    }
    .dropdown {
        margin-left: 20px;
    }
    .status, .dropdown {
        position: relative;
        .close {
            display: none;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
        .tree {
            display: inline-block;
            max-width: 100px;
            vertical-align: middle;
        }
    }
}
.section {
    position: relative;
}
.included-section {
    position: relative;
}
.devices-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 1140px;
    .device-block {
        position: relative;
        margin: 0 18px 18px 0;
        width: 362px;
        .img-wrap {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 204px;
            background: #cfcfcf;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.5);
            .device-status {
                position: absolute;
                left: 18px;
                bottom: 20px;
                height: 21px;
                padding: 0 5px;
                border-radius: 4px;
                background: #000;
                color: #fff;
                .text {
                    margin-left: -5px;
                    font-size: 10px;
                }
            }
            .remove-wrap {
                position: absolute;
                right: 16px;
                bottom: 16px;
                width: 34px;
                height: 34px;
                background: rgba(0,0,0,0.4);
                border-radius: 50%;
                &:hover {
                    background: rgba(0,0,0,0.6);
                }
                .icon-remove {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #f8fbff;
                }
            }
        }
        .checkbox {
            position: absolute;
            left: 0;
            top: 0;
            margin-right: 0;
            padding: 10px 0 0 18px;
            width: 100%;
            height: 204px;
        }
        .device-info {
            width: 100%;
            .device-title {
                display: inline-block;
                position: relative;
                width: 50%;
                font-size: 14px !important;
            }
            .text {
                display: inline-block;
                width: 50%;
                font-size: 12px;
                text-align: right;
            }
        }
    }
}
.selected-title {
    margin-left: -15px;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 700;
    .remove-all {
        margin-left: 38px;
    }
}
</style>