<template>
    <div class="screen-group">
        <Button class="add-group" type="primary" @click="handleAdd">{{ $t('pages.screen.addGroup') }}</Button>
        <div class="action-bar">
            <RadioGroup class="radio-group-button" v-model="mode" type="button" @on-change="handleMode">
                <Radio label="all">{{ $t('common.all') }}</Radio>
                <Radio label="horizontal">{{ $t('pages.screen.horizontal') }}</Radio>
                <Radio label="vertical">{{ $t('pages.screen.vertical') }}</Radio>
            </RadioGroup>
        </div>
        <no-data v-show="list.length === 0" :text="$t('pages.screen.noGroup')"></no-data>
        <div v-show="list.length > 0" class="group-wrap">
            <div class="group-item" v-for="(item) in list" :key="item.rowid">
                <div class="img-wrap-block">
                    <div :class="['img-wrap', item.dir==='vertical'?'img-wrap-vertical':'']">
                        <img class="image-center" :src="`/cym/${item.cover_uri}`">
                    </div>
                </div>
                <div class="mask pointer" @click="handlePlayPlan(item)"></div>
                <div class="device-info">
                    <div class="device-name name-wrap pointer" @click="showElement($event, item.rowid, 10)">
                        <div class="name line-overflow">
                            {{ item.name }}
                        </div>
                        <Icon class="icon" type="md-create" />
                    </div>
                    <div v-if="item.department_name && is_org" class="device-site line-overflow">{{ item.department_name }}</div>
                    <div class="device-common line-overflow pointer" @click="handlePlayPlan(item)">{{ $t('pages.screen.plan') }}</div>
                    <div class="device-common device-list line-overflow pointer" @click="handleScreenList(item)">{{ $t('pages.screen.screen') }}({{ item.terminal_count }})</div>
                </div>
                <Dropdown class="more" placement="bottom-start" trigger="click" >
                    <a href="javascript:void(0)">
                        <!-- <Button icon="md-more" shape="circle"></Button> -->
                        <Icon class="icon-more" type="md-more" color="#2d2d2d" :size="22" />
                    </a>
                    <DropdownMenu slot="list" >
                        <DropdownItem v-allow="'device'" @click.native="handleVolume(item.rowid)">{{ $t('pages.screen.volume') }}</DropdownItem>
                        <DropdownItem v-allow="'device'" @click.native="handleOnOff(item.rowid)">{{ $t('pages.screen.onOff') }}</DropdownItem>
                        <DropdownItem @click.native="handleRemove([item.rowid])">{{ $t('common.del') }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <Spin fix v-if="loading"></Spin>
        </div>
        <Page
            v-show="list.length > 0"
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />
        <add-group-modal
            :show="addGroupModalShow"
            :dirList="dirList"
            :department_id="department_id"
            @confirm="handleaddGroupConfirm"
            @cancel="handleaddGroupCancel"
        ></add-group-modal>
        <screen-modal
            :show="screenModalShow"
            :statusList="statusList"
            :groupInfo="groupInfo"
            :is_org="is_org"
            @confirm="handleScreenConfirm"
            @cancel="handleScreenCancel"
        ></screen-modal>
        <play-plan-modal
            :show="playPlanModalShow"
            :group="true"
            :groupInfo="groupInfo"
            @cancel="handlePlayPlanModalCancel"
        ></play-plan-modal>
        <play-setting
            :show="playSettingModalShow"
            :group="true"
            :groupInfo="groupInfo"
            @cancel="handlePlaySettingCancel"
            @confirm="handlePlaySettingConfirm"
        ></play-setting>
        <volume-modal
            :group="true"
            :show="volumeModalShow"
            :groupId="groupId"
            @cancel="handleVolumeCancel"
            @confirm="handleVolumeSubmit"
        ></volume-modal>
        <on-off-modal
            :group="true"
            :show="onOffModalShow"
            :groupId="groupId"
            :onRefresh="handleOnOffSubmit"
            @cancel="handleOnOffCancel"
            @confirm="handleOnOffSubmit"
        ></on-off-modal>
        <add-modal
            :show="addModalShow"
            :department_id="department_id"
            :title="$t('pages.screen.addScreenTip')"
            @cancel="handleAddCancel"
            @confirm="handleAddSubmit"
        ></add-modal>
    </div>  
</template>
<script>
    import addGroupModal from './components/addGroup'
    import screenModal from './components/screen'
    import playPlanModal from '../components/playPlan'
    import playSetting from '../components/playSetting'
    import volumeModal from '../components/volume'
    import onOffModal from '../components/onOff'
    import addModal from '@/views/screens/mediaScreens/components/add'
    import noData from '@/views/components/noData'
    import { rename } from '@/mixins/index'
    import { dropdownTree } from '@/mixins/index'


    export default {
        name: "",
        mixins: [rename, dropdownTree],
        components: {
            addGroupModal,
            screenModal,
            playPlanModal,
            playSetting,
            volumeModal,
            onOffModal,
            noData,
            addModal
        },
        props: {
            is_org: {
                default: false
            },
            dirList: {
                default: () => {
                    return []
                }
            },
            statusList: {
                default: () => {
                    return []
                }
            },
            department_id: {
                default: null
            },
            tree_data: {
                default: () => {
                    return []
                }
            },
            handleGroupTotal: Function
        },
        data () {
            return {
                total: 0,
                current: 1,
                pageSize: 32,
                loading: false,
                list: [],
                addGroupModalShow: false,
                screenModalShow: false,
                groupInfo: {},
                playPlanModalShow: false,
                groupId: null,
                playSettingModalShow: false,
                volumeModalShow: false,
                onOffModalShow: false,
                group_department_id: null,
                treeList: [],
                mode: 'all',
                addModalShow: false
            }
        },
        computed: {},
        methods: {
            handleAdd() {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                this.addGroupModalShow = true
            },
            handleAddCancel() {
                this.addModalShow = false
            },
            handleAddSubmit() {
                this.$store.dispatch('GET_ACCESS_RIGHTS')
            },
            handleMode() {
                this.current = 1
                this.getData()
            },
            handleaddGroupConfirm(params) {
                this.handleaddGroupCancel()
                this.getData()
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.screen.addTipCon'),
                    onOk: () => {
                        this.screenModalShow = true
                        this.groupInfo = params
                    }
                })
            },
            handleaddGroupCancel() {
                this.addGroupModalShow = false
            },
            handlePlayPlan(item) {
                this.groupInfo = item
                this.playPlanModalShow = true
            },
            handlePlayPlanModalCancel() {
                this.playPlanModalShow = false
            },
            handleRemove(list) {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.screen.delGroupTip'),
                    onOk: () => {
                        let newList = [...list],
                            len = newList.length,
                            str = ''
                        for (let i = 0; i < len; i++) {
                            str += newList[i]
                            if (i !== len - 1) {
                                str += ','
                            }
                        }
                        this.$api.delete(`terminal_groups?rowid_list=${str}`,).then(() => {
                            this.$Message.success(this.$t('message.success'))
                            let len = list.length;
                            if(this.total - len === (this.current - 1) * this.pageSize && this.current > 1) {
                                this.current -= 1
                            }
                            this.getData()
                        })
                    }
                })
            },
            handleScreenList(item) {
                this.groupInfo = item
                this.screenModalShow = true
            },
            handleScreenCancel() {
                this.screenModalShow = false
            },
            handleScreenConfirm(params) {
                this.getData()
                if (params) {
                    this.playSettingModalShow = true
                    this.groupInfo.terminal_count = params.terminal_count
                }
            },
            handlePlaySettingCancel() {
                this.playSettingModalShow = false
            },
            handlePlaySettingConfirm() {
                this.handlePlaySettingCancel()
                // this.getData()
            },
            handleRename(name, rowid) {
                let params = {
                    name: name
                }
                this.$api.put(`terminal_groups/${rowid}/name`, params).then(()=>{
                    this.$Message.success(this.$t('message.success'))
                    this.getData()
                })
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: '',
                    dir: '',
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }

                if (this.is_org) {
                    this.treeList = []
                    this.getTreeList(this.tree_data, 'department_id')
                    params.department_id_list = JSON.stringify(this.treeList)
                } else {
                    params.department_id_list = JSON.stringify([this.department_id])
                }

                if (this.mode !== 'all') {
                    params.dir = this.mode
                }

                this.loading = true
                this.$api.get('terminal_groups/search', { params }).then(({ data, page, total_count }) => {
                    if (page === this.current) {
                        this.list = data
                        this.total = total_count
                        this.handleGroupTotal(total_count)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleVolumeCancel() {
                this.volumeModalShow = false
            },
            handleVolumeSubmit(volume) {
                let params = {
                    rowid: this.groupId,
                    volume: volume
                }
                this.$api.put(`terminal_groups/${this.groupId}/volume`, params).then(() => {
                    this.$Message.success(this.$t('message.success'))
                })
            },
            handleVolume(rowid) {
                this.groupId = rowid
                this.volumeModalShow = true
            },
            handleOnOffSubmit(params, done) {
                if (params.current_state) {
                    this.$api.put(`terminal_groups/${this.groupId}/screen/power_state`, params).then(() => {
                        this.$Message.success(this.$t('message.success'))
                        this.handleOnOffCancel()
                    }).finally(() => {
                        done()
                    })
                    return
                }
                this.$api.put(`terminal_groups/${this.groupId}/screen/schedule`, params).then(() => {
                    this.$Message.success(this.$t('message.success'))
                    this.handleOnOffCancel()
                }).finally(() => {
                    done()
                })
            },
            handleOnOffCancel() {
                this.onOffModalShow = false
            },
            handleOnOff(rowid) {
                this.groupId = rowid
                this.onOffModalShow = true
            }

        },
        mounted() {
            this.getData()
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
@deep: ~'>>>';
.screen-group {
    .add-group {
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .action-bar {
        .radio-group-button {
            position: relative;
            margin-left: -15px;
        }
    }
    .group-wrap {
        position: relative;
        width: calc(100% + 8px);
        .group-item {
            position: relative;
            display: inline-block;
            margin: 0 8px 31px 0;
            border: 1px solid #d3d3d3;
            width: calc(25% - 8px);
            padding-left: 0.7%;
            padding-top: 13%;
            background: #fff;
            &:hover {
                .mask {
                    opacity: 1;
                }
            }
            .mask {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.08);
                transition: opacity .3s ease;
            }
            .img-wrap-block {
                position: absolute;
                left: 0;
                top: 0;
                width: 38%;
                height: 100%;
                background: #f6f7f9;
                .img-wrap {
                    position: absolute;
                    top: 31%;
                    bottom: 31%;
                    left: 10%;
                    right: 10%;
                }
                .img-wrap-vertical {
                    position: absolute;
                    top: 15%;
                    bottom: 15%;
                    left: 25%;
                    right: 25%;
                }
            }
            .device-info {
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translateY(-50%);
                width: 58%;
                min-width: 100px;
                .device-name {
                    position: relative;
                    height: 33px;
                    font-weight: 700;
                    font-size: 10px;
                    width: calc(100% + 16px);
                    @{deep} .input {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: calc(100% - 16px) !important;
                        transform: translateY(-50%);
                        vertical-align: middle;
                    }
                    .name {
                        display: inline-block;
                        position: relative;
                        max-width: calc(100% - 24px);
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 33px;
                        vertical-align: middle;
                    }
                    .icon {
                        vertical-align: middle;
                    }
                }
                .device-site {
                    font-size: 10px;
                }
                .device-common {
                    padding: 0 10px;
                    height: 28px;
                    width: 80px;
                    background: @primary-color;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    line-height: 28px;
                    vertical-align: top;
                    font-size: 12px;
                }
                .device-list {
                    margin-top: 8px;
                }
            }
            .more {
                position: absolute;
                right: 3%;
                bottom: 3%;
                .icon-more {
                    border: 2px solid #2d2d2d;
                    border-radius: 50% 50%;
                }
            }
        }
        @media screen and (min-width: 1600px) {
            .group-item {
                .img-wrap-block {
                    .img-wrap {
                        .device-status {
                            bottom: 31%;
                        }
                    }
                }
                .device-info {
                    right: 8%;
                    .device-name {
                        font-size: 14px;
                        @{deep} .input {
                            font-size: 14px !important;
                        }
                        .name {
                            font-size: 14px;
                        }
                    }
                    .device-site {
                        font-size: 12px;
                    }
                    .device-common {
                        height: 35px;
                        width: 100px;
                        line-height: 35px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>